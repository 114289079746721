import axios from "axios";
import {saveData, loadData} from "@scripts/LocalStorage";
import {ServerWww} from "@constants/servers";

/**
 * 获取AI入口配置
 * @param userId
 * @param token
 */
export const fetchAiEntry = async ( userId:string, token:string = `` )
:Promise<[Error, AiEntryConfig[]]> => {
    try {
        const key = `ai-entry-configs` ;
        let data:AiEntryConfig[] = loadData ( key, userId, true ) ;
        if ( !data ) {
            const res = await axios ( {
                url: `${ServerWww}/sysAction/getAvailableList`,
                params: {
                    token
                }
            } as any );
            data = res.data.data ;
            const expires = __DEV__ ? 0.1 : 5 ;
            saveData ( key, userId, res.data.data, { expires: expires, unit: `minute` }, true ) ;
        }
        return [null, data] ;
    } catch ( e ) {
        return [e, null] ;
    }
}

export type AiEntryConfig = {
    action_key: string,
    show_name: string,
    icon_url: string,
    from_text: string,
    [name:string]: any
}
/**
 * 根据入口名称获取入口配置
 * @param aiEntryConfigs
 * @param name
 */
export const getAiEntryByName = ( aiEntryConfigs:AiEntryConfig[], name:string ) => {
    if ( !aiEntryConfigs ) return null ;
    return aiEntryConfigs.find ( item => item.action_key === name ) ;
}