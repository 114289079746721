/* eslint-disable no-unused-expressions,no-sequences,no-cond-assign,no-useless-escape */
import { Store } from "vuex" ;
import Router, { Route } from "vue-router" ;
import { BigBigWork } from "@scripts/BigBigWork" ;
import * as Cookies from "js-cookie" ;
import {
    PagePinliteVip,
    PageSearchAuth,
    PageVersionDiff,
    PageVersionTeamDiff,
    PageVersionTeamReDiff,
    PageGoToPay
} from "@constants/pages" ;
import axios from 'axios' ;
import { identifyPornAndTerror, getLoginClientState, sensitiveWordsFilter } from "@constants/servlet" ;
import { Base64 } from 'js-base64' ;
import AES from "crypto-js/aes" ;
import EncUtf8 from "crypto-js/enc-utf8" ;
import { isDev } from "./utils" ;
import Bowser from "bowser" ;
import { pathToRegexp } from 'path-to-regexp' ;
import qs from "qs" ;
import Cookie from "js-cookie" ;
import { isPinliteDomain, ServerWww } from "../constants/servers" ;
import { ResultItem } from "@scripts/resultItem" ;
import EncBase64 from "crypto-js/enc-base64" ;
import ModeEcb from "crypto-js/mode-ecb" ;
import PadPkcs7 from "crypto-js/pad-pkcs7" ;
import _slice from "lodash-es/slice" ;
import cloneDeep from "lodash-es/cloneDeep" ;

/**
 * 转换url为Image
 * @param url
 * @param opts
 */
export const convertUrlToImage = async ( url: string, opts?: {
    attrs: { [key: string]: any }
} ): Promise<HTMLImageElement> => {
    return new Promise ( ( resolve, reject ) => {
        const { attrs = {} } = opts ?? {} ;
        const img = new Image () ;
        Object.keys ( attrs ).forEach ( key => {
            img.setAttribute ( key, attrs[key] ) ;
        } ) ;
        img.onload = function ( e ) {
            resolve ( e.target as HTMLImageElement ) ;
        } ;
        img.onerror = reject ;
        if ( !url?.match (/^\w+:/ ) ) {
            url = `data:image/png;base64,${url}` ;
        }
        img.src = url ;
    } ) ;
} ;

/** 获取用户token */
export const getToken = ():string => {
    return Cookies.get ( `jhk-personal` ) || Cookies.get ( `dashisousuo` ) ;
} ;
/** 一像素透明图片 */
export const emptyImg = `data:image/gif;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVQImWNgYGBgAAAABQABh6FO1AAAAABJRU5ErkJggg==` ;
/** 获取系统信息 */
export const getOS = () => {
    const ua = window.navigator.userAgent ;
    const platform = window.navigator.platform ;
    const os:any = {} ;
    const browser:any = {} ;
    const webkit = ua.match ( /Web[kK]it[\/]{0,1}([\d.]+)/ ) ;
    const android = ua.match ( /(Android);?[\s\/]+([\d.]+)?/ ) ;
    const osx = !!ua.match ( /\(Macintosh\; Intel / ) ;
    const mac = ua.indexOf ( `Mac OS` ) > -1 ;
    const ipad = ua.match ( /(iPad).*OS\s([\d_]+)/ ) ;
    const ipod = ua.match ( /(iPod)(.*OS\s([\d_]+))?/ ) ;
    const iphone = !ipad && ua.match ( /(iPhone\sOS)\s([\d_]+)/ ) ;
    const webos = ua.match ( /(webOS|hpwOS)[\s\/]([\d.]+)/ ) ;
    const win = ( /Win\d{2}|Windows/ ).test ( platform ) ;
    const wp = ua.match ( /Windows Phone ([\d.]+)/ ) ;
    const touchpad = webos && ua.match ( /TouchPad/ ) ;
    const kindle = ua.match ( /Kindle\/([\d.]+)/ ) ;
    const silk = ua.match ( /Silk\/([\d._]+)/ ) ;
    const blackberry = ua.match ( /(BlackBerry).*Version\/([\d.]+)/ ) ;
    const bb10 = ua.match ( /(BB10).*Version\/([\d.]+)/ ) ;
    const rimtabletos = ua.match ( /(RIM\sTablet\sOS)\s([\d.]+)/ ) ;
    const playbook = ua.match ( /PlayBook/ ) ;
    const chrome = ua.match ( /Chrome\/([\d.]+)/ ) || ua.match ( /CriOS\/([\d.]+)/ ) ;
    const firefox = ua.match ( /Firefox\/([\d.]+)/ ) ;
    const edge = ua.match ( /Edge\/([\d.]+)/ ) ;
    const firefoxos = ua.match ( /\((?:Mobile|Tablet); rv:([\d.]+)\).*Firefox\/[\d.]+/ ) ;
    const ie = ua.match ( /MSIE\s([\d.]+)/ ) || ua.match ( /Trident\/[\d](?=[^\?]+).*rv:([0-9.].)/ ) ;
    const webview = !chrome && ua.match ( /(iPhone|iPod|iPad).*AppleWebKit(?!.*Safari)/ ) ;
    const safari = webview || ua.match ( /Version\/([\d.]+)([^S](Safari)|[^M]*(Mobile)[^S]*(Safari))/ ) ;
    const weixin = ua.match ( /MicroMessenger[\/]{0,1}([\d.]+)/ ) ;
    if ( browser.webkit = !!webkit ) browser.version = webkit[1] ;
    if ( android ) os.android = true, os.version = android[2] ;
    if ( mac ) os.mac = true ;
    if ( iphone && !ipod ) os.ios = os.iphone = true, os.version = iphone[2].replace ( /_/g, `.` ) ;
    if ( ipad ) os.ios = os.ipad = true, os.version = ipad[2].replace ( /_/g, `.` ) ;
    if ( ipod ) os.ios = os.ipod = true, os.version = ipod[3] ? ipod[3].replace ( /_/g, `.` ) : null ;

    if ( wp ) os.wp = true, os.version = wp[1] ;
    if ( webos ) os.webos = true, os.version = webos[2] ;
    if ( touchpad ) os.touchpad = true ;
    if ( blackberry ) os.blackberry = true, os.version = blackberry[2] ;
    if ( bb10 ) os.bb10 = true, os.version = bb10[2] ;
    if ( rimtabletos ) os.rimtabletos = true, os.version = rimtabletos[2] ;
    if ( playbook ) browser.playbook = true ;
    if ( kindle ) os.kindle = true, os.version = kindle[1] ;
    if ( silk ) browser.silk = true, browser.version = silk[1] ;
    if ( !silk && os.android && ua.match ( /Kindle Fire/ ) ) browser.silk = true ;
    if ( chrome ) browser.chrome = true, browser.version = chrome[1] ;
    if ( firefox ) browser.firefox = true, browser.version = firefox[1] ;
    if ( firefoxos ) os.firefoxos = true, os.version = firefoxos[1] ;
    if ( edge ) browser.edge = true, browser.version = edge[1] ;
    if ( ie ) browser.ie = true, browser.version = ie[1] ;
    if ( safari && ( osx || os.ios || win ) ) {
        browser.safari = true ;
        if ( !os.ios ) browser.version = safari[1] ;
    }
    if ( webview ) browser.webview = true ;
    if ( weixin ) browser.weixin = true, browser.version = weixin[1] ;

    os.tablet = !!( ipad || playbook || ( android && !ua.match ( /Mobile/ ) ) ||
        ( firefox && ua.match ( /Tablet/ ) ) || ( ie && !ua.match ( /Phone/ ) && ua.match ( /Touch/ ) ) ) ;
    os.phone = !!( !os.tablet && !os.ipod && ( android || iphone || webos || blackberry || bb10 ||
        ( chrome && ua.match ( /Android/ ) ) || ( chrome && ua.match ( /CriOS\/([\d.]+)/ ) ) ||
        ( firefox && ua.match ( /Mobile/ ) ) || ( ie && ua.match ( /Touch/ ) ) ) ) ;
    os.desktop = !os.tablet && !os.phone ;
    return {
        os: os,
        browser: browser
    } ;
} ;
/** 替换协议为相对协议 */
export const toRelativeProtocol = ( url:string, newProtocol = `//` ) => {
    if ( typeof url !== `string` || !url.trim () ) return `` ;
    if ( url.match ( /^(blob:)|(data:)/ ) ) return url ;
    try {
        return url.replace ( /^((http|https):)?\/\//, newProtocol ) ;
    } catch ( e ) {
        isDev () && console.log ( e ) ;
    }
} ;
/** 是否支持 WEBP */
export const checkSupportWEBP = ():Promise<boolean> => {
    return new Promise ( ( resolve, reject ) => {
        if ( process.env.VUE_ENV === `server` ) {
            resolve ( false ) ;
        }
        const webpTestsUri = `data:image/webp;base64,UklGRiQAAABXRUJQVlA4IBgAAAAwAQCdASoBAAEAAwA0JaQAA3AA/vuUAAA=` ;
        const image = new Image () ;
        function addResult ( event ) {
            const flag = event && event.type === `load` ? image.width === 1 : false ;
            resolve ( flag ) ;
        }
        image.onerror = addResult ;
        image.onload = addResult ;
        image.src = webpTestsUri ;
    } ) ;
} ;

/** 跳转地址,如果是网页地址则跳转页面，是路由地址则跳转路由 */
export const jumpPage = ( router:Router, redirect, isReplace:boolean = false ) => {
    if ( typeof redirect === `string` && redirect.match ( /^((https|http):)?\/\// ) ) {
        if ( isReplace ) {
            locationPush ( redirect, `replace` ) ;
        } else {
            locationPush ( redirect ) ;
        }
    } else {
        const url = router.resolve ( redirect ).resolved ;
        if ( isReplace ) {
            routerPush ( router, url, true ) ;
        } else {
            routerPush ( router, url ) ;
        }
    }
} ;

export interface initStateReadyOptions {
    /** 需要获取的state字符串 */
    state: string,
    /** 获取state的action字符串 */
    fetchAction: string,
    /** action请求成功触发的事件名 */
    fetchSuccessEvent: string,
    /** action请求失败触发的事件名 */
    fetchFailedEvent:string,
    /** action 参数 */
    actionData?: any
}
/**
 * 初始化一个高阶函数，返回一个函数，此函数保证获取state的请求只调用一次
 * @example
 * const getState:Promise<string> = initStateReady<string>(this.$store,  {
                state: "PinUser/name",
                fetchAction: 'PinUser/FETCH_PIN_NAME',
                fetchSuccessEvent: "PinUser/FETCH_PIN_NAME",
                fetchFailedEvent:"PinUser/FETCH_PIN_NAME_FAIL",
                actionData: 0
            })
    //在多个地方同时调用getState时，不会反复发出请求，当获取到state之后，会统一触发回调
    await getState()
 */
export const initStateReady = <T>( store:Store<unknown>, options:initStateReadyOptions ) => {
    const { state, fetchAction, fetchSuccessEvent, fetchFailedEvent, actionData = {} } = options ;
    // 是否正在获取state
    let loading = false ;
    return ():Promise<T> => {
        return new Promise ( ( resolve, reject ) => {
            // 如果store中已有pinUserName则直接返回
            if ( store.state[state] ) {
                resolve ( store.state[state] ) ;
            }
            // 没有在获取状态，则发出ajax请求
            else if ( loading === false ) {
                loading = true ;
                store.dispatch ( fetchAction, actionData ).then ( res => {
                    loading = false ;
                    resolve ( store.state[state] as T ) ;
                } ).catch ( e => {
                    reject ( e ) ;
                } ) ;
            } else {
                // 已发出过请求，则添加事件监听，请求返回结果后触发事件
                BigBigWork.once ( fetchSuccessEvent, () => {
                    resolve ( store.state[state] as T ) ;
                    loading = false ;
                } ) ;
                BigBigWork.once ( fetchFailedEvent, ( e ) => {
                    reject ( e ) ;
                    loading = false ;
                } ) ;
            }
        } ) ;
    } ;
} ;

/** 下载图片 */
export const downloadImg = ( src:string ) => {
    const os:any = getOS () ;
    oDownLoad ( src ) ;
    // 下载图片,兼容两种不同下载方式
    function oDownLoad ( url ) {
        if ( os.browser.firefox || ( os.os.ipad && os.browser.chrome ) ) {
            download ( url ) ;
        } else {
            iframeDownload ( url ) ;
        }
    }
    function iframeDownload ( src ) {
        const iframe:HTMLIFrameElement = document.createElement ( `iframe` ) ;
        const kill = setTimeout ( () => {
            clearTimeout ( kill ) ;
            console.log ( `timeout` ) ;
        }, 10000 ) ;
        // @ts-ignore
        if ( iframe.attachEvent ) {
            // @ts-ignore
            iframe.attachEvent ( `onload`, function () {
                console.log ( `开始下载` ) ;
                clearTimeout ( kill ) ;
                // 这里可以执行其它操作
            } ) ;
        } else {
            iframe.onload = function () {
                console.log ( `开始下载` ) ;
                clearTimeout ( kill ) ;
            } ;
        }
        iframe.style.display = `none` ;
        iframe.src = src ;
        document.body.appendChild ( iframe ) ;
    }
} ;
/** 下载链接 */
export const download = async ( src:string, fileName:string = `dz-` + new Date ().getTime (), newTab:boolean = true ) => {
    // 创建隐藏的可下载链接
    const eleLink = document.createElement ( `a` ) ;
    eleLink.download = fileName ;
    eleLink.style.display = `none` ;
    eleLink.href = src ;
    if ( newTab ) {
        eleLink.target = `_blank` ;
    }
    // // 触发点击
    document.body.appendChild ( eleLink ) ;
    eleLink.click () ;
    window.setTimeout ( () => {
        // // 然后移除
        document.body.removeChild ( eleLink ) ;
    }, 100 ) ;
} ;

/** 大作下载图片(后期改ajax调用) */
export const searchDownloadImg = async ( src: string ) => {
    downloadImg ( src ) ;
    async function ajaxDownload () {
        const res = await axios ( {
            url: src,
            responseType: `blob`,
            headers: {
                "Content-type": `application/x-www-form-urlencoded`
            },
            onDownloadProgress: function ( res ) {
                console.log ( Math.round ( res.loaded / res.total * 100 ) + `%` ) ;
            }
        } ) ;
        if ( res.status === 200 ) {
            const link = document.createElement ( `a` ) ;
            const isBlob = res.data instanceof Blob ;
            const blob = new Blob ( [res.data], { type: `jpeg/png` } ) ;
            const csvUrl = URL.createObjectURL ( blob ) ;
            link.href = csvUrl ;
            link.download = `jhk-${new Date ().getTime ()}.jpg` ;
            link.click () ;
            window.setTimeout ( () => {
                // 需要释放,防止内存泄漏
                URL.revokeObjectURL ( csvUrl ) ;
            }, 1000 ) ;
        }
    }
} ;

interface getBase64ImageFn {
    ( img:string ): Promise<string>
    ( img:HTMLImageElement ): Promise<string>
}
/** imgDom转base64 图片需要允许跨域 */
export const getBase64Image:getBase64ImageFn = async ( img ):Promise<any> => {
    const drawBase64 = ( imgDom ) => {
        const width = imgDom.naturalWidth || imgDom.width ;
        const height = imgDom.naturalHeight || imgDom.height ;
        const canvas = document.createElement ( `canvas` ) ;
        canvas.width = width ;
        canvas.height = height ;
        const ctx = canvas.getContext ( `2d` ) ;
        ctx.drawImage ( imgDom, 0, 0, width, height ) ;
        const dataUrl = canvas.toDataURL ( `image/png` ) ;
        return dataUrl ;
    } ;
    if ( typeof img === `string` ) {
        const imgDom = document.createElement ( `img` ) ;
        imgDom.crossOrigin = `Anonymous` ;
        imgDom.src = img ;
        imgDom.hidden = true ;
        document.body.appendChild ( imgDom ) ;
        return new Promise ( ( resolve, reject ) => {
            imgDom.onload = function () {
                const dataUrl = drawBase64 ( imgDom ) ;
                document.body.removeChild ( imgDom ) ;
                resolve ( dataUrl ) ;
            } ;
            imgDom.onerror = function ( e ) {
                document.body.removeChild ( imgDom ) ;
                reject ( e ) ;
            } ;
        } ) ;
    } else {
        const base64 = drawBase64 ( img ) ;
        return Promise.resolve ( base64 ) ;
    }
} ;
/** 根据tag名寻找子元素 */
export const findChildNodsByTag = ( dom, tagname ) => {
    const result = [] ;

    function find ( node ) {
        if ( !node.childNodes.length ) return ;
        for ( let i = 0 ; i < node.childNodes.length ; i++ ) {
            if ( node.childNodes[i].nodeName.toLowerCase () === tagname.toLowerCase () ) {
                result.push ( node.childNodes[i] ) ;
            }
            find ( node.childNodes[i] ) ;
        }
    }
    find ( dom ) ;
    return result ;
} ;
/** 获取URL参数 */
export const getQueryString = ( name:string, path?:string ) => {
    const reg = new RegExp ( `(^|&)` + name + `=([^&]*)(&|$)`, `i` ) ;
    const urlpath = path || decodeURIComponent ( window.location.search ) ;
    const r = urlpath.substr ( 1 ).match ( reg ) ;
    if ( r != null ) return unescape ( r[2] ) ;
    return null ;
} ;
/** 获取URL参数 新版 */
export const getUrlParam = function ( paraName = ``, url = `` ) {
    if ( typeof window === `undefined` ) {
        return `` ;
    }
    !url ? url = window.location.href : url ;
    const path = url ;
    const arrObj = path.split ( `?` ) ;
    if ( arrObj.length > 1 ) {
        const arrPara = arrObj[1].split ( `&` ) ;
        let arr ;
        for ( let i = 0 ; i < arrPara.length ; i++ ) {
            arr = arrPara[i].split ( `=` ) ;
            if ( arr != null && arr[0] === paraName ) {
                return ( arr[1] ) ;
            }
        }
        return `` ;
    } else {
        return `` ;
    }
} ;
/** 将一个对象转换为formData */
export const convertParamsToFormData = ( params: object, resolveDefault = ( val ) => { return val ?? `` ; } ) => {
    const keys = Object.keys ( params ) ;
    const formData = new FormData () ;
    for ( let i = 0 ; i < keys.length ; i++ ) {
        const key = keys[i] ;
        const value = resolveDefault ( params[key] ) ;
        formData.append ( key, value ) ;
    }
    return formData ;
} ;
/** 根据Hostname获取根域名domain
 * @param hostname 网站host
 * @param firstDot 是否需要第一个点
 */
export const getDomain = ( hostname?:string, firstDot:boolean = true ):string => {
    const url = hostname || window?.location?.hostname ;
    const list:string[] = url.split ( `.` ) ;
    if ( list.length > 2 ) {
        list.shift () ;
    }
    let result = `` ;
    list.forEach ( ( val, index ) => {
        result += ( ( index === 0 && !firstDot ) ? `` : `.` ) + val ;
    } ) ;
    return result ;
} ;
/**
 * @description 将毫秒数转换为 00:00:00格式的事件
 * @param  time
 */
export const formatToString = ( time:number ) => {
    let t ;
    const s:number = time / 1000 ;
    if ( s > -1 ) {
        const hour = Math.floor ( s / 3600 ) ;
        const min = Math.floor ( s / 60 ) % 60 ;
        const sec = s % 60 ;
        if ( hour < 10 ) {
            t = `0` + hour + `:` ;
        } else {
            t = hour + `:` ;
        }

        if ( min < 10 ) {
            t += `0` ;
        }
        t += min + `:` ;
        if ( sec < 10 ) {
            t += `0` ;
        }
        t += sec ;
    }
    return t ;
} ;
/** 格式化时间 */
export const formatTime = ( fmt:string ) => {
    const date = new Date () ;
    const o = {
        "M+": date.getMonth () + 1,
        "d+": date.getDate (),
        "h+": date.getHours (),
        "HH+": date.getHours () > 12 ? ( date.getHours () - 12 ) : date.getHours (),
        "m+": date.getMinutes (),
        "s+": date.getSeconds (),
        "q+": Math.floor ( ( date.getMonth () + 3 ) / 3 ),
        S: date.getMilliseconds (),
        "aa+": date.getHours () > 12 ? `pm` : `am`
    } ;
    if ( ( /(y+)/ ).test ( fmt ) ) {
        fmt = fmt.replace ( RegExp.$1, ( date.getFullYear () + `` ).substr ( 4 - RegExp.$1.length ) ) ;
    }
    for ( const k in o ) {
        if ( new RegExp ( `(` + k + `)` ).test ( fmt ) ) {
            fmt = fmt.replace ( RegExp.$1, ( RegExp.$1.length === 1 ) ? ( o[k] ) : ( ( `00` + o[k] ).substr ( ( `` + o[k] ).length ) ) ) ;
        }
    }
    return fmt ;
} ;
/** 格式化时间(YYYY-MM-DD hh:mm) */
export const formatStringToTime = ( time:string, fmt ) => {
    // const date = new Date (time) ;
    // const o = {
    //     "Y": date.getFullYear (),
    //     "M": (date.getMonth () + 1) > 9 ? (date.getMonth () + 1):`0${date.getMonth () + 1}`,
    //     "d": date.getDate () > 9 ? date.getDate (): `0${date.getDate ()}`,
    //     "h": date.getHours () > 9 ? date.getHours (): `0${date.getHours ()}`,
    //     "m": date.getMinutes () > 9 ? date.getMinutes () : `0${date.getMinutes ()}`
    // } ;
    let date = eval ( time ) ;
    fmt = fmt || `yyyy-MM-dd hh:mm:ss` ;
    date = new Date ( date ) ;
    if ( ( /(y+)/ ).test ( fmt ) ) {
        fmt = fmt.replace ( RegExp.$1, ( date.getFullYear () + `` ).substr ( 4 - RegExp.$1.length ) ) ;
    }
    const o = {
        'M+': date.getMonth () + 1,
        'd+': date.getDate (),
        'h+': date.getHours (),
        'm+': date.getMinutes (),
        's+': date.getSeconds ()
    } ;
    for ( const k in o ) {
        if ( new RegExp ( `(${k})` ).test ( fmt ) ) {
            const str = o[k] + `` ;
            fmt = fmt.replace ( RegExp.$1, ( RegExp.$1.length === 1 ) ? str : ( `00` + str ).substr ( str.length ) ) ;
        }
    }
    return fmt ;
    // return `${o.Y}-${o.M}-${o.d} ${o.h}:${o.m}`;
} ;

type buyVipType = `bbw` | `pinlite` | `bbwTeam` | `bbwTeamRenew` | `graph`;

/** 购买VIP
 * @param type VIP类型（pinlite,bbw）
 * @param store vuex store
 * @param fromText 付费统计文字ƒ
 * @param fromType 付费统计类型
 * @param auth 是否需要通过auth页面中转
 * @param teamId 是否带团队id
 */
export const buyVip = ( type:buyVipType = null, store:Store<any>, fromText:string, fromType:number, auth?:boolean, teamId?:number ) => {
    if ( !type ) {
        if ( isPinliteDomain ( getDomain ().slice ( 0 ) ) ) {
            type = `pinlite` ;
        } else if ( window.location.href.match ( `bigbigwork.com` ) ) {
            type = `bbw` ;
        } else {
            type = `bbw` ;
            console.log ( `未设置的购买VIP类型` ) ;
        }
    }
    // 统计付费来源
    const msg = Object.assign (
        { from_text: fromText, from_type: fromType },
        {
            u_id: store.state.UserInfo.id,
            user_type: store.state.UserInfo.userType,
            action_source: 1
        }
    ) ;
    BigBigWork.emit ( `Analysis`, { type: `openPay`, data: msg } ) ;
    let url = `` ;
    switch ( type ) {
    case `pinlite`: url = PagePinliteVip + `?type=${fromType}&source=${EncryptionEncryptParam ( fromText )}` ; break ;
    case `bbwTeam`: url = PageVersionTeamDiff + `?type=${fromType}&source=${EncryptionEncryptParam ( fromText )}` ; break ;
    case `bbwTeamRenew`: url = PageVersionTeamReDiff + `/${teamId}?type=${fromType}&source=${EncryptionEncryptParam ( fromText )}` ; break ;
    case `bbw`:
    default: url = PageVersionDiff + `?type=${fromType}&source=${EncryptionEncryptParam ( fromText )}` ;
    }
    if ( auth ) {
        openLink ( `${PageSearchAuth}?token=${encodeURIComponent ( store.state.token )}&redirect=${encodeURIComponent ( url )}` ) ;
    } else {
        openLink ( url ) ;
    }
} ;
/** 深度替换协议头 */
export const deepToRelativeProtocol = ( data:object, newProtocol = `//` ) => {
    if ( typeof data === `object` ) {
        deepEach ( data, ( key, value, obj ) => {
            if ( typeof value === `string` ) {
                obj[key] = toRelativeProtocol ( value ) ;
            }
        } ) ;
    } else {
        console.warn ( `只能替换object对象` ) ;
    }
} ;

export const deepEach = ( data:object, handle?:( key:string, val:any, obj:Object )=>void, maxLevel = 100 ) => {
    try {
        const find = ( obj:object, level ) => {
            if ( level >= maxLevel ) {
                isDev () && console.warn ( `层数达到最大，请检查来源数据或更改最大层数` ) ;
                return true ;
            }
            for ( const key in obj ) {
                // eslint-disable-next-line no-prototype-builtins
                if ( obj.hasOwnProperty ( key ) ) {
                    const val = obj[key] ;
                    handle && handle ( key, val, obj ) ;
                    if ( typeof val === `object` ) {
                        find ( val, level += 1 ) ;
                    }
                }
            }
            return true ;
        } ;
        find ( data, 0 ) ;
    } catch ( e ) {
        isDev () && console.log ( e ) ;
    }
} ;

/**
 * @description 给图片鉴黄鉴暴
 * @param type 图片格式
 * @param file formData图片，base64 url 都可以
 */
interface greenImageScanRes {
    code: number,
    // eslint-disable-next-line camelcase
    oss_url: string
}
export const greenImageScan = function ( type, file ) :Promise<greenImageScanRes> {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise ( async ( resolve, reject ) => {
        switch ( type ) {
        case `formdata`:
            var formData = new FormData () ;
            formData.append ( `file`, file ) ;
            formData.append ( `user_token`, await getToken () ) ;
            axios ( {
                method: `post`,
                url: identifyPornAndTerror,
                data: formData,
                headers: {
                    'Content-Type': `multipart/form-data`
                }
            } ).then ( data => {
                const e = data.data ;
                if ( e.status === 200 ) {
                    resolve ( e.data ) ;
                }
            } ) ;
            break ;
        case `base64`:
            var imgformData = new FormData () ;
            imgformData.append ( `base64`, file ) ;
            imgformData.append ( `user_token`, await getToken () ) ;
            axios ( {
                method: `post`,
                url: identifyPornAndTerror,
                data: imgformData,
                headers: {
                    'Content-Type': `multipart/form-data`
                }
            } ).then ( data => {
                const e = data.data ;
                if ( e.status === 200 ) {
                    resolve ( e.data ) ;
                }
            } ) ;
            break ;
        default:
        }
    } ) ;
} ;

export const isLoginClient = async function (): Promise<Boolean> {
    const res = await axios ( {
        url: getLoginClientState,
        params: {
            token: await getToken ()
        }
    } ) ;
    if ( res.status === 200 ) {
        return !!res.data.data ;
    } else {
        return false ;
    }
} ;

export const detectOS = function () {
    var sUserAgent = navigator.userAgent ;
    var isWin = ( navigator.platform == `Win32` ) || ( navigator.platform == `Windows` ) ;
    var isMac = ( navigator.platform == `Mac68K` ) || ( navigator.platform == `MacPPC` ) || ( navigator.platform == `Macintosh` ) || ( navigator.platform == `MacIntel` ) ;
    if ( isMac ) return `Mac` ;
    var isUnix = ( navigator.platform == `X11` ) && !isWin && !isMac ;
    if ( isUnix ) return `Unix` ;
    var isLinux = ( String ( navigator.platform ).indexOf ( `Linux` ) > -1 ) ;
    if ( isLinux ) return `Linux` ;
    if ( isWin ) {
        var isWin2K = sUserAgent.indexOf ( `Windows NT 5.0` ) > -1 || sUserAgent.indexOf ( `Windows 2000` ) > -1 ;
        if ( isWin2K ) return `Win2000` ;
        var isWinXP = sUserAgent.indexOf ( `Windows NT 5.1` ) > -1 || sUserAgent.indexOf ( `Windows XP` ) > -1 ;
        if ( isWinXP ) return `WinXP` ;
        var isWin2003 = sUserAgent.indexOf ( `Windows NT 5.2` ) > -1 || sUserAgent.indexOf ( `Windows 2003` ) > -1 ;
        if ( isWin2003 ) return `Win2003` ;
        var isWinVista = sUserAgent.indexOf ( `Windows NT 6.0` ) > -1 || sUserAgent.indexOf ( `Windows Vista` ) > -1 ;
        if ( isWinVista ) return `WinVista` ;
        var isWin7 = sUserAgent.indexOf ( `Windows NT 6.1` ) > -1 || sUserAgent.indexOf ( `Windows 7` ) > -1 ;
        if ( isWin7 ) return `Win7` ;
        var isWin10 = sUserAgent.indexOf ( `Windows NT 10` ) > -1 || sUserAgent.indexOf ( `Windows 10` ) > -1 ;
        if ( isWin10 ) return `Win10` ;
    }
    return `other` ;
} ;
/** 网络错误 */
export class NetError extends Error {
    name:string = `NetError`;
}
/** 未知错误 */
export class UnknownError extends Error {
    name:string = `UnknownError`;
}

/** 自定义错误类型 */
export type CustomErrorType<T = {[name:string]:any}> = {
    code: number,
    data: T,
    message: string,
    status: number
}
/** 自定义错误 */
export class CustomError<T = {[name:string]:any}> extends Error {
    data: T
    code: number
    message: string
    original: any
    constructor ( options:Partial<CustomErrorType<T>> ) {
        const { code, message, data, status } = options ;
        super ( message ) ;
        this.code = code ?? status ;
        this.message = message ?? `` ;
        this.data = data ;
        this.original = cloneDeep ( ( options ?? {} ) ) ;
    }

    get status () {
        return this.code ;
    }

    set status ( value ) {
        this.code = value ;
    }
}

/**
 * @description 截取字符串，中文为2、英文为1，超过长度加上"..."
 * @param str:字符串,n:长度
 */
export const subEngAndCH = function ( str:string, n: number ): string {
    // eslint-disable-next-line no-control-regex
    var r = /[^\x00-\xff]/g ;
    if ( str.replace ( r, `**` ).length <= n ) {
        return str ;
    }
    var m = Math.floor ( n / 2 ) ;
    for ( var i = m ; i < str.length ; i++ ) {
        if ( str.substr ( 0, i ).replace ( r, `**` ).length >= n ) {
            return str.substr ( 0, i - 3 ) + `...` ;
        }
    }
    return str ;
} ;
export const encodeBase64 = ( str ) => {
    return Base64.encode ( str ) ;
} ;
export const decodeBase64 = ( str ) => {
    return Base64.decode ( str ) ;
} ;
/** 检测文本是否溢出 */
export const checkOverflow = ( el ) => {
    const curOverflow = el.style.overflow ;
    if ( !curOverflow || curOverflow === `visible` ) {
        el.style.overflow = `hidden` ;
    }
    const isOverflowing = el.clientWidth < el.scrollWidth ||
        el.clientHeight < el.scrollHeight ;

    el.style.overflow = curOverflow ;

    return isOverflowing ;
} ;

/** 等待用户数据初始化 */
export const waitLogin = async ( store:Store<any> ):Promise<boolean> => {
    return new Promise ( ( resolve, reject ) => {
        if ( store.state.UserInfo?.finished ) {
            return resolve ( true ) ;
        }
        // const unsubscribe = store.subscribe (( mutation, payload ) => {
        //     if ( mutation.type === `UserInfo/setFinish` ) {
        //         unsubscribe () ;
        //         return resolve ( true ) ;
        //     }
        // })
        BigBigWork.once ( `onUserInfoReady`, () => {
            return resolve ( true ) ;
        } ) ;
        BigBigWork.once ( `onUserInfoFailed`, () => {
            return resolve ( false ) ;
        } ) ;
    } ) ;
} ;
/** 等待用户登录后调用
 * @param store
 */
export const waitUserLogin = async ( store:Store<any> ):Promise<boolean> => {
    return new Promise ( ( resolve, reject ) => {
        if ( store.state.UserInfo?.id > -1 ) {
            return resolve ( true ) ;
        }
        BigBigWork.once ( `onUserInfoReady`, () => {
            return resolve ( true ) ;
        } ) ;
        BigBigWork.once ( `onUserInfoFailed`, () => {
            return resolve ( false ) ;
        } ) ;
    } ) ;
} ;

export const waitTime = ( interval:number ) => {
    return new Promise ( ( resolve, reject ) => {
        window.setTimeout ( () => {
            resolve ( true ) ;
        }, interval ) ;
    } ) ;
} ;

/** 等待用户数据初始化 */
export const waitUserFinished = async ( store:Store<any> ):Promise<boolean> => {
    return new Promise ( ( resolve, reject ) => {
        if ( store.state.UserInfo?.finished ) {
            return resolve ( true ) ;
        }
        const unsubscribe = store.subscribe ( ( mutation, payload ) => {
            if ( mutation.type === `UserInfo/setFinish` ) {
                unsubscribe () ;
                return resolve ( true ) ;
            }
        } ) ;
    } ) ;
} ;

/** 获取滚动条宽度 */
export const getScrollbarWidth = () => {
    const odiv = document.createElement ( `div` ) ;
    const styles = {
        width: `100px`,
        height: `100px`,
        overflowY: `scroll`
    } ;
    for ( const i in styles ) odiv.style[i] = styles[i] ;
    document.body.appendChild ( odiv ) ;
    const scrollbarWidth = odiv.offsetWidth - odiv.clientWidth ;
    document.body.removeChild ( odiv ) ;
    return scrollbarWidth ;
} ;

/** 关键词是否需要翻译 */
export const needTranslate = ( str:string, maxLength:number = Number.MAX_VALUE ) => {
    const result = str.match ( /[\u4e00-\u9fa5]+/i ) ;
    return !!result && ( str.length < maxLength ) ;
} ;

/** 加密解密key */
export const EncryptionKey = `eGlubHUucWl1` ;

/** 加密一个值
 * @param value 要加密的值
 * @param key 密码
 * @constructor
 */
export const EncryptionEncryptParam = ( value: string, key:string = EncryptionKey ):string => {
    return encodeURIComponent ( AES.encrypt ( value, key ).toString () ) ;
} ;
/** 解密一个值
 * @param value 要解密的值
 * @param key 密码
 * @constructor
 */
export const EncryptionDecryptParam = ( value: string, key:string = EncryptionKey ):string => {
    const de = AES.decrypt ( decodeURIComponent ( value ), key ) ;
    return de.toString ( EncUtf8 ) ;
} ;
/** 前后端联调用加密数据 */
export const EncryptionData = ( word:string, keyBase64 = `ZUdsdWJIVXVjV2wxMTIzNA==` ) => {
    const key = EncBase64.parse ( keyBase64 ) ;
    const srcs = EncUtf8.parse ( word ) ;
    const encrypted = AES.encrypt ( srcs, key, { mode: ModeEcb, padding: PadPkcs7 } ) ;
    return encrypted.toString () ;
} ;
/** 前后端联调用解密数据 */
export const DecryptData = ( word:string, keyBase64 = `ZUdsdWJIVXVjV2wxMTIzNA==` ) => {
    const key = EncBase64.parse ( keyBase64 ) ;
    const decrypt = AES.decrypt ( word, key, { mode: ModeEcb, padding: PadPkcs7 } ) ;
    return EncUtf8.stringify ( decrypt ).toString () ;
} ;
/**
 * @description 阻止滚动穿透
 */
export const scrollUnique = function () {
    var eventType = `mousewheel` ;
    if ( ( document as any ).mozHidden !== undefined ) {
        eventType = `DOMMouseScroll` ;
    }
    return function ( ele ) {
        ele.addEventListener ( eventType, function ( event ) {
            const scrollTop = this.scrollTop ;
            const scrollHeight = this.scrollHeight ;
            const height = this.clientHeight ;
            const delta = ( event.wheelDelta ) ? event.wheelDelta : -( event.wheelDelta || 0 ) ;

            if ( ( delta > 0 && scrollTop <= delta ) || ( delta < 0 && scrollHeight - height - scrollTop <= -1 * delta ) ) {
                // IE浏览器下滚动会跨越边界直接影响父级滚动，因此，临界时候手动边界滚动定位
                this.scrollTop = delta > 0 ? 0 : scrollHeight ;
                // 向上滚 || 向下滚
                event.preventDefault () ;
            }
        } ) ;
    } ;
} ;
/**
 * @description 解析url
 */
export const ParseUrl = function ( url: string, getName: string ) {
    const parseReg = /^(?:([A-Za-z]+):)?(\/{0,3})([0-9.\-A-Za-z]+)(?::(\d+))?(?:\/([^?#]*))?(?:\?([^#]*))?(?:#(.*))?$/ ;
    const result = parseReg.exec ( url ) ;
    const fields = [`url`, `scheme`, `slash`, `host`, `port`, `path`, `query`, `hash`] ;
    const mapName = {
        url: 0,
        scheme: 1,
        slash: 2,
        host: 3,
        port: 4,
        path: 5,
        query: 6,
        hash: 7
    } ;
    return result[mapName[getName]] ;
} ;

/** 获取缓存pinlite用户名的localstorage键 */
export const getPinUserCacheName = ( type:number = 1 ) => {
    return `pinuser-${type}` ;
} ;

/** 获取设备字符串 */
export const getDeviceFingerprint = ( ) => {
    const info = Bowser.parse ( window.navigator.userAgent ) ;
    // @ts-ignore
    const fingerprint = `${info.os.name};${info.os.version};${info.platform.type};${window.devicePixelRatio};${window.screen.height};${window.screen.width};` ;
    return fingerprint ;
} ;
/** 当前页面是否是Pilnite页面地址 */
export const isPinlite = () => {
    const domainHasPinlite = window.location.host.indexOf ( `${process.env.PINLITE_DOMAIN}` ) > -1 ;
    // const regPinlite = pathToRegexp ( `/pinlite/(.*)` ) ;
    const isPinliteUrl = !!( /^\/pinlite(\/|$)/ ).exec ( window.location.pathname ) ;
    return domainHasPinlite || isPinliteUrl ;
} ;

/** 是否是M站 */
export const isSearchMobile = () => {
    return window.location.host.search ( /\.bigbigwork\.com/ ) > -1 ;
} ;

/** 是否在大作APK */
export const isDazuoAPK = ( ua:string ):RegExpExecArray => {
    const DAZUO_REGEXP = /dazuo\s*\/(\d+\.\d+)\s*$/ ;
    const res = DAZUO_REGEXP.exec ( ua ) ;
    return res ;
} ;
/**
 * @description 检查是否是违禁词
 */
export const CheckIsForbiddenWord = async ( word: string ): Promise<false|true> => {
    try {
        if ( word.trim () === `` ) return false ;
        const res = await axios ( {
            url: `${sensitiveWordsFilter}?word=${encodeURIComponent ( word )}`
        } ) ;
        if ( res.status === 200 && res.data.status === 200 && !!res.data.data ) {
            return true ;
        }
        return false ;
    } catch ( e ) {
        return false ;
    }
} ;
/** 自定义路由跳转 */
export const routerPush = async ( router:Router, config, replace:boolean = false, isWeixin:boolean = true ) => {
    try {
        const browser = Bowser.parse ( window.navigator.userAgent ) ;
        const params:any = config ;
        if ( browser.browser.name.toLowerCase () === `wechat` ) {
            if ( !params.query ) { params.query = {} ; }
            if ( isWeixin ) { params.query.weixin = true ; }
        } else {
            params?.query && ( delete params.query.weixin ) ;
        }
        if ( replace ) {
            await router.replace ( { ...config, ...params } ) ;
        } else {
            await router.push ( { ...config, ...params } ) ;
        }
    } catch ( e ) {
        throw e ;
    }
} ;

export type LocationPushType = `replace` | `push` | `blank` ;
/** 自定义页面跳转 */
export const locationPush = async ( redirect:string, type:LocationPushType = `push` ) => {
    try {
        const browser = Bowser.parse ( window.navigator.userAgent ) ;
        const url = new URL ( redirect, window.location.protocol + `//` + window.location.host ) ;
        const isWeixin = browser.browser.name.toLowerCase () === `wechat` ;
        if ( isWeixin ) {
            url.searchParams.append ( `weixin`, `true` ) ;
        }
        const urlStr = url.toString () ;
        switch ( type ) {
        case `blank`: window.open ( urlStr ) ; break ;
        case `push`: window.location.href = urlStr ; break ;
        case `replace`: window.location.replace ( urlStr ) ; break ;
        }
    } catch ( e ) {
        throw e ;
    }
} ;

export const openLink = ( url ) => {
    const sysInfo = Bowser.parse ( window.navigator.userAgent ) ;
    if ( sysInfo.browser.name === `Internet Explorer` ) {
        window.open ( url ) ;
    } else {
        const a = document.createElement ( `a` ) ;
        a.style.display = `none` ;
        a.href = url ;
        a.target = `_blank` ;
        document.body.appendChild ( a ) ;
        a.dispatchEvent ( new MouseEvent ( `click` ) ) ;
        setTimeout ( ( ) => {
            document.body.removeChild ( a ) ;
        } ) ;
    }
} ;

/** 将hash转换成object */
export const parseHash = ( hash: string ):any => {
    let paramsStr = hash ;
    if ( hash[0] === `#` ) {
        paramsStr = hash.slice ( 1 ) ;
    }
    return qs.parse ( paramsStr ) ;
} ;
export const checkIsPwa = () => {
    if ( typeof window !== `undefined` ) {
        return window.matchMedia ( `(display-mode: standalone)` ).matches ;
    }

    return false ;
} ;
/** 用户注册时间是否满24小时 */
export const newUserLoginTime = ( $store ) => {
    const now = $store.state.UserInfo?.now ;
    const createTime = $store.state.UserInfo?.createTime ;
    const timeDiff = now - createTime ;
    const nowhours = 24 * 60 * 60 * 1000 ;
    return timeDiff > nowhours ;
} ;
/** 受邀用户过期时间 14天 */
export const beInvitedUserOverdue = ( $store ) => {
    const overdueDateTime = ( $store.state.UserInfo?.createTime || 0 ) + 14 * 24 * 60 * 60 * 1000 ;
    const month = `${new Date ( overdueDateTime ).getMonth () + 1}`.padStart ( 2, `0` ) ;
    const date = `${new Date ( overdueDateTime ).getDate ()}`.padStart ( 2, `0` ) ;
    return `${month}月${date}日` ;
} ;
/** 是否还在受邀有效期内 */
export const isInvitedOverdue = ( $store ) => {
    return ( $store.state.UserInfo?.now ?? 0 ) > ( ( $store.state.UserInfo?.createTime ?? 0 ) + 14 * 24 * 60 * 60 * 1000 ) ;
} ;
/**
 * 获取未来第几天0点的时间
 * @param str
 * @returns {Uint8Array | *}
 */
export const getNextDate = ( futureDays:number = 1 ) => {
    const d = new Date () ;
    const year = d.getFullYear () ;
    const month = d.getMonth () ;
    const day = d.getDate () ;
    const nextDay = new Date ( year, month, ( day + futureDays ), 0, 0, 0 ) ;
    return nextDay ;
} ;
/** 复制到粘贴板 */
export const copyToClipboard = ( text: string ) => {
    const input = document.createElement ( `input` ) ;
    document.body.appendChild ( input ) ;
    input.setAttribute ( `value`, text ) ;
    input.select () ;
    if ( document.execCommand ( `copy` ) ) {
        document.execCommand ( `copy` ) ;
    }
    document.body.removeChild ( input ) ;
} ;

/** 记录sem参数 */
export const tracertUTM = () => {
    const source = getQueryString ( `utm_source` ) ;
    let data:any = {} ;
    if ( source == null || source == `` ) {

    } else {
        data = {
            source: source,
            medium: getQueryString ( `utm_medium` ),
            term: getQueryString ( `utm_term` ),
            campaign: getQueryString ( `utm_campaign` )
        } ;
        var content = getQueryString ( `utm_content` ) ;
        if ( content != null && content != `` ) {
            data.content = content ;
        }
    }
    data.url = window.location.host + window.location.pathname ;
    Cookie.set ( `jhktracert`, JSON.stringify ( data ), {
        expires: new Date ( new Date ().getTime () + 1 * 60 * 60 * 1000 ),
        domain: getDomain ()
    } ) ;
} ;
/**
 * @description 点击下载校验2s间隔
 */
export const canDownloadWithInterval = ( ops:{ id: number} ) => {
    let preDownloadInfo = null ;
    const localstoragePreDownloadInfo = Cookies.get ( `preDownloadInfo` ) ;
    // eslint-disable-next-line no-unused-expressions
    localstoragePreDownloadInfo ? preDownloadInfo = JSON.parse ( localstoragePreDownloadInfo ) : {} ;
    if ( preDownloadInfo ) {
        return preDownloadInfo.id !== ops.id ;
    }
    return true ;
} ;
/**
 * @description 相同图片2s内禁止连续点击
 * */
export const isForbiddenDownload = ( ops: {id: number} ) => {
    if ( !canDownloadWithInterval ( ops ) ) {
        return true ;
    }
    setDownloadTime ( ops ) ;
    return false ;
} ;
export const setDownloadTime = ( ops: {id: number} ) => {
    const curTime = new Date ().getTime () ;
    Cookies.set ( `preDownloadInfo`, JSON.stringify ( { time: curTime, id: ops.id } ), {
        expires: new Date ( curTime + 2000 ),
        domain: getDomain ()
    } ) ;
} ;
/**
 * @description blob转base64
 * @param blob
 */
export const blobToDataUrl = ( blob ): Promise<string | ArrayBuffer> => {
    const reader = new FileReader () ;
    return new Promise ( ( resolve, reject ) => {
        reader.onload = function ( e ) {
            resolve ( e.target.result ) ;
        } ;
        reader.readAsDataURL ( blob ) ;
    } ) ;
} ;

/** 并发发出promise任务，全部完成后返回结果 */
export const allPromise = ( tasks: Array<Promise<any>> ):Promise<any> => {
    const num = tasks.length ;
    const result = [] ;
    return new Promise ( ( resolve, reject ) => {
        const _checkEnd = () => {
            if ( result.length === num ) {
                resolve ( result ) ;
                return true ;
            }
            return false ;
        } ;
        for ( const task of tasks ) {
            task.then ( res => {
                result.push ( res ) ;
                _checkEnd () ;
            } ).catch ( e => {
                result.push ( e ) ;
                _checkEnd () ;
            } ) ;
        }
    } ) ;
} ;

/** 根据组件名字找到父组件 */
export const findParentComponentByName = ( name:string, vm ) => {
    const find = ( node ) => {
        if ( node.$vnode?.componentOptions?.Ctor?.options?.name === name ) return node ;
        if ( node.$parent === node || !node.$parent ) return null ;
        return find ( node.$parent ) ;
    } ;
    return find ( vm ) ;
} ;
/** 查找第一个符合筛选器的父节点 */
export const findParentNodeBySelector = ( node:HTMLElement, selector:string ):HTMLElement => {
    const selectorNodes = document.querySelectorAll ( selector ) ;
    const find = ( node:HTMLElement ) => {
        const isMatchNode = Array.prototype.some.call ( selectorNodes, val => val === node ) ;
        if ( isMatchNode ) return node ;
        if ( !node.parentElement ) return null ;
        return find ( node.parentElement ) ;
    } ;
    return find ( node ) ;
} ;
export const debounce = function ( fn, delay ) {
    let timmer = null ;
    return function () {
        const args = arguments ;
        const _this = this ;
        if ( timmer ) {
            clearTimeout ( timmer ) ;
        }
        timmer = setTimeout ( function () {
            fn.apply ( _this, args ) ;
        }, delay ) ;
    } ;
} ;

/** 设置瀑布流显示尺寸(最高500，最小100) **/
export const resizeItem = <T extends ResultItem>( item:T, width:number = 332, maxHeight:number = 800, paddingBottom = 0 ):T => {
    const h = 100 / 750 * width / item.w * item.h ;
    item.dw = 100 / 750 * width ;
    item.dh = h + paddingBottom ;
    item.dh = Math.max ( Math.min ( 100 / 750 * maxHeight, item.dh ), 100 / 750 * 100 ) ;
    return item ;
} ;

/** 获取瀑布流尺寸 */
export const getSize = ( item, maxWidth = 236 ) => {
    let width = maxWidth ;
    if ( item.w < maxWidth ) {
        width = item.w ;
    }
    const height = width / item.w * item.h ;
    return {
        width: width + `px`, height: height + `px`
    } ;
} ;

/** 打开pinlite */
export const openPinlite = async ( store:Store<any>, redirect?:string ):Promise<boolean> => {
    const token = getToken () ;
    const prefix = __DEV__ ? `www-test` : `www` ;
    const url = `https://${prefix}.${store.state.PinUser.pinliteDomain}/auth?token=${encodeURIComponent ( token )}${redirect ? `&redirect=${encodeURIComponent ( redirect )}` : ``}` ;
    // 先检查是否登录，跳过会弹出弹窗阻拦的浏览器
    const browser = Bowser.getParser ( window.navigator.userAgent ) ;
    const browserName = browser.getBrowserName ( true ) ;
    const notCheck = browserName === `firefox` || browserName === `safari` || browserName === `internet explorer` ;
    if ( !notCheck ) {
        store.dispatch ( `UserInfo/FETCH_CHECK_BROWSER_UNIQUE`, { source: `pc_new` } ).then ( () => {
            // 打开pinlite
            openLink ( url ) ;
            return true ;
        } ) ;
    } else {
        // 打开pinlite
        openLink ( url ) ;
        return true ;
    }
} ;
/**
 * @description 验证字符串
 * @param {string} type (预设为：手机：'telephone'； 电子邮件：'email'；其他验证：直接传入正则表达式，如果不在预设中则使用此正则检测；)
 * @param {string} value  |
 */
export const checker = function ( type, value ) {
    let regexp ;
    switch ( type ) {
    case `telephone`:
        regexp = new RegExp ( /^[1|2|3]([0-9]{1}[0-9]{1})[0-9]{8}$/ ) ;
        break ;
    case `email`:
        regexp = new RegExp ( /^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$/ ) ;
        break ;
    case `symbol`:
        regexp = new RegExp ( /[\\,\/,:,?,",<,>,|,\@,\$,%,&]{1,}/ ) ;
        break ;
    case `name`:
        regexp = new RegExp ( /^[a-zA-Z0-9\u4e00-\u9fa5]{1,12}$/ ) ;
        break ;
    default:
        regexp = new RegExp ( type ) ;
    }
    if ( regexp ) { return regexp.test ( value ) ; }
    return false ;
} ;
/**
 * 截取字符串(支持unicode)
 * @param str
 * @param beginIndex
 * @param endIndex
 */
export const slice = ( str:string, beginIndex:number = 0, endIndex:number = str.length ):string => {
    const res = _slice ( str, beginIndex, endIndex ) ;
    let text = `` ;
    res.forEach ( val => {
        text += val ;
    } ) ;
    return text ;
    // let index = 0 ;
    // let result = `` ;
    // if ( beginIndex === endIndex ) return ``;
    // const start = beginIndex < 0 ? str.length + beginIndex : beginIndex ;
    // const end = endIndex < 0 ? str.length + endIndex : endIndex ;
    // if ( end < start ) return result ;
    // for ( const i of str ) {
    //     if ( index >= start ) {
    //         result += i ;
    //     }
    //     if ( index > end ) break ;
    //     index++ ;
    // }
    // return result ;
} ;
/** 加密用户VIP信息 */
export const encodeInfo = ( store:Store<any> ) => {
    const isVip = store.getters[`UserInfo/isVip`] ;
    const randomNum = ( input ) => {
        if ( input === 0 ) {
            const nums = [0, 2, 4, 6, 8] ;
            const randomIndex = Math.floor ( Math.random () * nums.length ) ;
            return nums[randomIndex] ;
        } else if ( input === 1 ) {
            const nums = [1, 3, 5, 7, 9] ;
            const randomIndex = Math.floor ( Math.random () * nums.length ) ;
            return nums[randomIndex] ;
        } else {
            return 0 ;
        }
    } ;
    const code = randomNum ( isVip ? 1 : 0 ) + `` ;
    const now:string = new Date ().getTime () + `` ;
    // 将code替换now中的最后第三位
    const newNow = now.slice ( 0, now.length - 3 ) + code + now.slice ( now.length - 2 ) ;
    return newNow ;
} ;
/** 根据最大边缩放图片
 * @param maxSide
 * @param width
 * @param height
 */
export const resizeContain = ( outerBoxWidth: number, outerBoxHeight: number, width:number, height:number ) => {
    const ratio = Math.min ( outerBoxWidth / width, outerBoxHeight / height ) ;
    width = width * ratio ;
    height = height * ratio ;
    const isResize = width !== outerBoxWidth || height !== outerBoxHeight ;
    return { width, height, isResize, ratio } ;
} ;
/** 根据最大边缩放图片
 * @param source 图片
 * @param opts 参数
 */
export const resizeImage = async ( source: HTMLImageElement | File | Blob | string, opts?: {
    maxSize?: number,
    outPutType?: `blob-url` | `base64` | `file` | `image`,
    canvasSize?: {
        width: number,
        height: number
    }
} ): Promise<[Error|null, {res:File | string | HTMLImageElement, w: number, h:number}]> => {
    return new Promise ( async ( resolve, reject ) => {
        try {
            const { maxSize = 1024, outPutType = `base64`, canvasSize } = opts ;
            let img:HTMLImageElement ;
            if ( source instanceof Blob ) {
                img = new Image () ;
                img.src = URL.createObjectURL ( source ) ;
                await new Promise ( ( resolve, reject ) => {
                    img.addEventListener ( `load`, () => resolve ( true ) ) ;
                    img.addEventListener ( `error`, () => reject ( new Error ( `图片加载错误` ) ) ) ;
                } ) ;
            } else if ( typeof source === `string` ) {
                img = new Image () ;
                img.crossOrigin = `anonymous` ;
                img.src = source ;
                img.crossOrigin = `anonymous` ;
                await new Promise ( ( resolve, reject ) => {
                    img.addEventListener ( `load`, () => resolve ( true ) ) ;
                    img.addEventListener ( `error`, () => reject ( new Error ( `图片加载错误` ) ) ) ;
                } ) ;
            } else {
                img = source ;
            }
            let useCanvasW ;
            let useCanvasH ;
            let useImageW ;
            let useImageH ;
            let flag = false ;
            if ( canvasSize ) {
                // 使用画布尺寸，图片最大化居中显示，剩余填充黑色
                useCanvasW = Math.min ( canvasSize.width, maxSize ) ;
                useCanvasH = Math.floor ( useCanvasW * canvasSize.height / canvasSize.width ) ;
                const { width, height, isResize } = resizeContain ( useCanvasW, useCanvasH, img.naturalWidth, img.naturalHeight ) ;
                useImageW = width ;
                useImageH = height ;
                flag = isResize ;
            } else {
                // 使用图片尺寸，图片以最大边等比缩放显示
                const { width, height, isResize } = resizeContain ( maxSize, maxSize, img.naturalWidth, img.naturalHeight ) ;
                useCanvasW = width ;
                useCanvasH = height ;
                useImageW = width ;
                useImageH = height ;
                flag = isResize ;
            }

            // if ( useCanvasW > maxSize || useCanvasH > maxSize ) {
            //     if ( useCanvasW > useCanvasH ) {
            //         useCanvasH = Math.floor( useCanvasH * maxSize / useCanvasW )
            //         useCanvasW = maxSize ;
            //     } else if ( useCanvasW === useCanvasH ) {
            //         useCanvasW = maxSize ;
            //         useCanvasH = maxSize ;
            //     } else {
            //         useCanvasW = Math.floor( useCanvasW * maxSize / useCanvasH ) ;
            //         useCanvasH = maxSize ;
            //     }
            //     flag = true ;
            // }
            const canvas = document.createElement ( `canvas` ) ;
            const canvasCtx = canvas.getContext ( `2d` ) ;
            const referenceImg = img ;
            canvas.width = useCanvasW ;
            canvas.height = useCanvasH ;
            // 填充cavas的背景为黑色
            canvasCtx.fillStyle = `#000` ;
            canvasCtx.fillRect ( 0, 0, useCanvasW, useCanvasH ) ;
            canvasCtx.drawImage (
                referenceImg,
                0,
                0,
                img.naturalWidth,
                img.naturalHeight,
                ( useCanvasW - useImageW ) * 0.5,
                ( useCanvasH - useImageH ) * 0.5,
                useImageW,
                useImageH
            ) ;
            if ( outPutType === `base64` ) {
                const base64 = canvas.toDataURL ( `image/png`, 1 ) ;
                resolve ( [null, { res: base64, w: useCanvasW, h: useCanvasH }] ) ;
            } else if ( outPutType === `file` ) {
                canvas.toBlob ( ( blob ) => {
                    const file = new File ( [blob], new Date ().getTime ().toString (), { type: `image/png` } ) ;
                    resolve ( [null, { res: file, w: useCanvasW, h: useCanvasH }] ) ;
                }, `image/png`, 1 ) ;
            } else if ( outPutType === `image`) {
                const url = canvas.toDataURL ( `image/png`, 1 ) ;
                const image = await convertUrlToImage ( url );
                resolve ( [null, { res: image, w: useCanvasW, h: useCanvasH }] ) ;
            } else {
                canvas.toBlob ( ( blob ) => {
                    const file = new File ( [blob], new Date ().getTime ().toString (), { type: `image/png` } ) ;
                    const url = URL.createObjectURL ( file ) ;
                    resolve ( [null, { res: url, w: useCanvasW, h: useCanvasH }] ) ;
                }, `image/png`, 1 ) ;
            }
        } catch ( e ) {
            resolve ( [e, null] ) ;
        }
    } ) ;
} ;
export const isIpad = () => {
    const osInfo = Bowser.getParser ( window.navigator.userAgent ) ;
    const browserName = osInfo.getBrowserName ( true ) ;
    const isSafari = browserName === `safari` ;
    const isMac = osInfo.getOSName ( true ) === `macos` ;
    return isSafari && isMac && navigator.maxTouchPoints > 4 ;
} ;
export const toDazuoUrl = ( redirect?:string, from?:string ) => {
    const token = getToken () ;
    const redirectUrl = redirect ? `&redirect=${encodeURIComponent ( redirect )}` : `` ;
    const fromInfo = from ?? window?.location?.href ?? `` ;
    const fromUrl = fromInfo ? `&from=${encodeURIComponent ( fromInfo )}` : `` ;
    return `${ServerWww}/auth.html?token=${encodeURIComponent ( token )}${redirectUrl}${fromUrl}` ;
} ;

/** 加载脚本
 * @param url 脚本地址
 * @param globalValue 全局变量
 * @param attrs 脚本属性
 */
export const loadScript = async (
    url:string,
    globalValue:string = ``,
    attrs:Record<string, string> = {}
) => {
    if ( typeof window === `undefined` ) {
        return ;
    }
    if ( globalValue && window[globalValue] ) {
        return ;
    }
    return new Promise ( ( resolve, reject ) => {
        const script = document.createElement ( `script` ) ;
        script.src = url ;
        Object.keys ( attrs ).forEach ( key => {
            script.setAttribute ( key, attrs[key] ) ;
        } ) ;
        script.onload = async () => {
            await waitTime ( 100 ) ;
            resolve ( true ) ;
        } ;
        script.onerror = ( e ) => {
            reject ( e ) ;
        } ;
        document.body.appendChild ( script ) ;
    } ) ;
} ;

/** 生成uuid */
export const generateUUID = ( formatString = `xxxxxxxxxxxxxxxx` ):string => {
    return formatString.replace ( /[xy]/g, function ( c ) {
        const r = Math.random () * 16 | 0 ;
        const v = c === `x` ? r : ( r & 0x3 | 0x8 ) ;
        return v.toString ( 16 ) ;
    } ) ;
} ;

/** 是否支持触摸 */
export const isSupportTouch = () => {
    return `ontouchstart` in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0 ;
} ;
/** 获取事件名称 */
export const getEventNames = () => {
    if ( isSupportTouch () ) {
        return {
            start: `touchstart`,
            move: `touchmove`,
            end: `touchend`
        } ;
    } else {
        return {
            start: `mousedown`,
            move: `mousemove`,
            end: `mouseup`
        } ;
    }
} ;

/** 将触摸事件转换成鼠标事件 */
export const touchToMouse = (
    event: TouchEvent | MouseEvent
): MouseEvent => {
    if ( event instanceof MouseEvent ) {
        return event ;
    }
    const touch = event.changedTouches[0] || event.touches[0] ;
    const mouseEvent = new MouseEvent ( `mousedown`, {
        clientX: touch.clientX,
        clientY: touch.clientY,
        screenX: touch.screenX,
        screenY: touch.screenY
    } ) ;
    return mouseEvent ;
}

export type ReadStreamResult = {
    type: string,
    data: any
} ;
/** 读取流式数据
 * @param prevResult 上一轮的分割结果
 * @param prevPos 上一轮分割结果侯剩余的文本的起始位置
 * @param progressEvent 本轮的进度事件
 * @param opts 配置
 */
export const readStream = (
    prevResult:ReadStreamResult[],
    prevPos: number,
    progressEvent:any,
    opts?: {
        dataExp?: RegExp, // data: {...}
        separatorExp?: RegExp, // -----{...}-----
        autoParse?: boolean // 是否自动解析json
    }
):{ result:ReadStreamResult[], totalResults: ReadStreamResult[], dataEndIndex: number, isEnd: boolean } => {
    const {
        dataExp = /^\s*data:\s*({(.*)})\s*$/,
        separatorExp = /^\s*-----\s*(.*)\s*-----\s*$/,
        autoParse = true
    } = opts ?? {} ;
    const { loaded, total, target } = progressEvent ;
    // 总文本
    const totalText = target.responseText as string ;
    // 是否结束
    let isEnd = false ;
    // 存储本轮的文本
    let curStr = `` ;
    // 将上一轮剩余的文本放入本轮文本
    const startPos = Math.min ( prevPos, loaded ) + 1 ;
    /** 开始读取文本 */
    const result:ReadStreamResult[] = [] ; // 存储结果
    let tempLine = `` ; // 临时行
    let templateData = `` ; // 临时数据
    let readIndex = 0 ; // 读取的位置
    // 计算本轮文本
    curStr = totalText.slice ( startPos, loaded ) ;
    let dataEndIndex = startPos ; // 数据结束位置
    while ( readIndex < curStr.length ) {
        const char = curStr[readIndex] ;
        if ( char === `\n` || char === `\r` || readIndex > curStr.length - 1 ) {
            // 测试是否分隔符
            const separatorMatch = separatorExp.exec ( tempLine ) ;
            // console.log ( `separatorMatch`, separatorMatch ) ;
            if ( separatorMatch ) {
                // 如果有数据，则保存
                if ( templateData ) {
                    const type = separatorMatch[1];
                    let data:any = templateData ;
                    if ( autoParse ) {
                        try {
                            data = JSON.parse ( templateData ) ;
                        } catch ( e ) {
                            data = {};
                            console.error ( `解析数据失败`, e ) ;
                        }
                    }
                    result.push ( {
                        type: type,
                        data: data
                    } ) ;
                    dataEndIndex = startPos + readIndex ;
                    templateData = `` ;
                }
            }
            // 测试是否是数据
            const dataMatch = dataExp.exec ( tempLine ) ;
            // console.log ( `dataMatch`, dataMatch ) ;
            // 如果是数据，则保存
            if ( dataMatch ) {
                templateData = dataMatch[1];
            }
            tempLine = `` ;
        } else {
            tempLine += char ;
        }
        readIndex++ ;
    }
    if ( readIndex >= total ) {
        isEnd = true ;
    }
    const totalResults:ReadStreamResult[] = [...prevResult, ...result] ;
    return { result, totalResults, dataEndIndex, isEnd } ;
}

/** 读取流式数据 ( 行分割 )
 * @param prevResult 上一轮的分割结果
 * @param prevPos 上一轮分割结果侯剩余的文本的起始位置
 * @param progressEvent 本轮的进度事件
 * @param opts 配置
 */
export const readStreamLine = (
    prevResult:ReadStreamResult[],
    prevPos: number,
    progressEvent:any,
    opts?: {
        dataExp?: RegExp, // 数据正则
        separatorExp?: RegExp, // 分隔符正则
        parser?: ( data:string ) => any // 解析函数
    }
):{ result:ReadStreamResult[], totalResults: ReadStreamResult[], dataEndIndex: number, isEnd: boolean } => {
    const {
        dataExp = /^\s*data:\s*({(.*)})(\s|\r|\n)*$/,
        separatorExp = /^(\n|\r)*$/
    } = opts ?? {} ;
    const defaultParser = ( data:string ) => {
        try {
            const content = dataExp.exec ( data )[1] ;
            return JSON.parse ( content ) ;
        } catch ( e ) {
            console.warn ( e, data ) ;
            return null ;
        }
    } ;
    const parser = opts?.parser ?? defaultParser ;
    const { loaded, total, target } = progressEvent ;
    // 总文本
    const totalText = target.responseText as string ;
    // 是否结束
    let isEnd = false ;
    // 存储本轮的文本
    let curStr = `` ;
    // 将上一轮剩余的文本放入本轮文本
    const startPos = Math.min ( prevPos, loaded ) ;
    /** 开始读取文本 */
    const result:ReadStreamResult[] = [] ; // 存储结果
    let tempLine = `` ; // 临时行
    let templateData = `` ; // 临时数据
    let readIndex = 0 ; // 读取的位置
    // 计算本轮文本
    curStr = totalText.slice ( startPos + 1, loaded ) ;
    let dataEndIndex = startPos ; // 数据结束位置
    while ( readIndex < curStr.length ) {
        const char = curStr[readIndex] ;
        if ( char === `\n` || char === `\r` || readIndex > curStr.length - 1 ) {
            // console.log ( `templateData`, templateData, readIndex, char );
            // 换行后对临时数据做处理
            // const isSeparator = separatorExp.exec ( tempLine ) ;
            let isData = null ;
            isData = dataExp.exec ( templateData ) ;
            if ( isData && isData[0] ) {
                // console.log ( `isData`, isData[0] ) ;
                const data = parser ( isData[0] ) ;
                if ( data ) {
                    // console.log ( `parser success:`, data ) ;
                    result.push ( data ) ;
                }
                templateData = `` ;
                dataEndIndex = startPos + readIndex ;
            } else {
                // 如果一行不是完整数据，将这一行的数据加入到临时数据中
                if ( templateData.trim () ) {
                    console.log ( `not Data`, templateData ) ;
                }
                const prevChar = curStr[readIndex - 1] ;
                const isLineData = dataExp.exec ( tempLine ) ;
                // 如果有临时数据，且不是数据行，则将换行符替换为<br>，防止接口数据中自带\n
                if ( templateData.trim() && !isLineData && char === `\n` ) {
                    if ( prevChar !== `\n` ) {
                        templateData += `\\n` ;
                    }
                }
                templateData += tempLine ;
            }
            tempLine = `` ;
        } else {
            tempLine += char ;
        }
        readIndex++ ;
    }
    if ( readIndex >= total ) {
        isEnd = true ;
    }
    const totalResults:ReadStreamResult[] = [...prevResult, ...result] ;
    // console.log ( `totalResults`, totalResults ) ;
    return { result, totalResults, dataEndIndex, isEnd } ;
} ;