import LRUCache from "lru-cache";
import { Module, Store } from "vuex" ;

export type ContextState = {
    url: string,
    token: string,
    t: string,
    d: string,
    k: string,
    isMobile: string,
    userAgent: string,
    cookies: [],
    cache: { [key:string]: LRUCache<string, any>},
    serverLogin: boolean,
    exContext: any,
    cacheName: string
}
export const Context: Module< ContextState, any> = {
    namespaced: true,
    state: {
        /** request的url */
        url: ``,
        /** request的token */
        token: ``,
        /** 是否移动端 */
        isMobile: ``,
        /** request的ua */
        userAgent: ``,
        /** request的cookie */
        cookies: [],
        /** tdk */
        t: `AI绘图-在线免费AI作图_ai绘画提示词及作品分享网站-大设网`,
        d: `大设网是基于Stable Diffusion的免费ai绘画网站，为ai作画爱好者提供一键生成高清精绘大图、sdxl模型保姆级教程、AI提示词工具。在大作ai人工智能绘画平台随意发挥自己的绘画创意。`,
        k: `大设,ai作图,ai绘画,ai绘画生成器,ai绘画网站,ai作画,ai绘图,midjourney,stable diffusion,sdxl,openai,元宇宙,AIGC,chatgpt,文生图,图生图,ai提示词,AI家居`,
        cache: {},
        serverLogin: false,
        exContext: {},
        cacheName: `` // 服务器端缓存名，来自routeMeta计算
    },
    mutations: {
        setContext ( state, payload = {} ) {
            const keys = Object.keys ( payload );
            for ( const k of keys ) {
                state[k] = payload[k]
            }
        },
        setUrl ( state, payload:string ) {
            state.url = payload ;
        },
        setToken: function ( state, val ) {
            state.token = val ;
        },
        setCache: function ( state, data ) {
            const { key, value } = data ;
            state.cache[key] = value ;
        },
        clearCache: function ( state ) {
            state.cache = {} ;
        },
        setTDK: function ( state, payload: {t:string, d:string, k:string }) {
            state.t = payload.t ;
            state.d = payload.d ;
            state.k = payload.k ;
            if ( typeof document !== `undefined` ) {
                document.title = payload.t ;
            }
        },
        setExContext: function ( state, payload ) {
            state.exContext = { ...state.exContext, ...payload } ;
        }
    }
} ;

export const CreatedContext = ( store: Store<unknown> ) => {
    if ( store.hasModule ( `ContextData` ) ) return ;
    store.registerModule ( `Context`, Context ) ;
} ;
